<template>
    <div class="evaluation mt-5 cart-Page cart-Page-done">

        
        <div >
    
            <div class="container items">
                <h4 class="mt-5">
                    {{ $t("Submit the request") }}
                </h4>
    
                <div class="thanks my-5">
                    <div class="right">
                        <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
                    </div>
                    <h5 class="my-5">
                        {{ $t("Thank you for your request") }}
                    </h5>
                    <p class="mb-5">
                        {{ $t("An error occurred while making the payment !") }}
                    </p>
                </div>
            </div>
           
        </div>
    </div>
    </template>
    
    <script>
    export default {
        name: "sendRequest",
        data() {
            const lang = localStorage.lang || "en";
            return {
                lang,
                id: null,
                done: true,
            };
        },
        methods: {},
        created() {
          
            
        },
    };
    </script>
    
    <style></style>
    